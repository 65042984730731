import { setter, type ImmerStateCreator } from "./";

// types
import type { TokenPrices } from "~/utils/coingecko";
import type { AccountDelegation } from "~/utils/leocache";
import type { AccountVesting } from "~/utils/hive";

export interface DynamicGlobalProperties {
  head_block_number: number;
  head_block_id: string;
  time: string;
  current_witness: string;
  total_pow: number;
  num_pow_witnesses: number;
  virtual_supply: string;
  current_supply: string;
  confidential_supply: string;
  current_hbd_supply: string;
  confidential_hbd_supply: string;
  total_vesting_fund_hive: string;
  total_vesting_shares: string;
  total_reward_fund_hive: string;
  init_hbd_supply: string;
  total_reward_shares2: string;
  pending_rewarded_vesting_shares: string;
  pending_rewarded_vesting_hive: string;
  hbd_interest_rate: number;
  hbd_print_rate: number;
  maximum_block_size: number;
  current_aslot: number;
  recent_slots_filled: string;
  participation_count: number;
  last_irreversible_block_num: number;
  vote_power_reserve_rate: number;
  required_actions_partition_percent: number;
  target_votes_per_period: number;
  delegation_return_period: number;
  reverse_auction_seconds: number;
  available_account_subsidies: number;
  hbd_stop_percent: number;
  hbd_start_percent: number;
  next_maintenance_time: string;
  last_budget_time: string;
  content_reward_percent: number;
  vesting_reward_percent: number;
  sps_fund_percent: number;
  sps_interval_ledger: string;
  downvote_pool_percent: number;
}

export interface DelegationStatus {
  apr: number;
  delegated: number;
  payout: {
    daily: number;
    monthly: number;
  };
  type: "liquid" | "staked";
  state: AccountDelegation | null;
  vestings: AccountVesting;
  dynamicGlobalProperties: any;
  mutateVestings: () => Promise<void>;
}

export interface Median {
  base: number;
  quote: number;
}

type State = {
  dynamicGlobalProperties: DynamicGlobalProperties | null;
  tokenPrices: TokenPrices | null;
  delegation: DelegationStatus | null;
  median: Median;
  denom: number;
};

type Actions = {
  setDynamicGlobalProperties: (properties: DynamicGlobalProperties) => void;
  setTokenPrices: (prices: TokenPrices) => void;
  setDelegation: (delegation: AccountDelegation) => void;
  setMedian: (median: Median) => void;
  setDenom: (denom: number) => void;
};

export type WalletStore = State & Actions;

export const createWalletStore: ImmerStateCreator<WalletStore> = set => ({
  // state
  dynamicGlobalProperties: null,
  tokenPrices: null,
  delegation: null,
  median: {
    base: 1,
    quote: 1
  },
  denom: 1000,

  // actions
  setDynamicGlobalProperties: (properties: DynamicGlobalProperties) =>
    setter<DynamicGlobalProperties>(
      "wallet",
      set,
      "dynamicGlobalProperties",
      properties
    ),
  setTokenPrices: (prices: TokenPrices) =>
    setter<TokenPrices>("wallet", set, "tokenPrices", prices),
  setDelegation: (delegation: AccountDelegation) =>
    setter<AccountDelegation>("wallet", set, "delegation", delegation),
  setMedian: (median: Median) =>
    setter<Median>("wallet", set, "median", median),
  setDenom: (denom: number) => setter<number>("wallet", set, "denom", denom)
});
