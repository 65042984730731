import { setter, type ImmerStateCreator } from "./";

// types
import type { CachedDiscussion } from "~/utils/leocache";

type State = {
  threadcasts: CachedDiscussion[];
};

type Actions = {
  setThreadcasts: (threadcasts: CachedDiscussion[]) => void;
};

export type DiscussionStore = State & Actions;

export const createDiscussionStore: ImmerStateCreator<
  DiscussionStore
> = set => ({
  // state
  threadcasts: [],

  // actions
  setThreadcasts: (threadcasts: CachedDiscussion[]) =>
    setter<CachedDiscussion[]>("discussion", set, "threadcasts", threadcasts)
});
