import { setter, type ImmerStateCreator } from ".";

type State = {
  tops: any;
};

type Actions = {
  setTops: (tops: any) => void;
};

export type LeaderboardStore = State & Actions;

export const createLeaderboardStore: ImmerStateCreator<
  LeaderboardStore
> = set => ({
  // state
  tops: {},

  // actions
  setTops: (tops: any) => setter<boolean>("leaderboard", set, "tops", tops)
});

