import { setter, type ImmerStateCreator } from "./";

export type OperationCategoryUnion = "profile" | "post" | "thread" | "community";
export type OperationTypeUnion = "edit";

export type StateItem = {
  type: 'edit'
  category: 'profile' | 'post' | 'thread' | 'community';
  id?: string;
};

export type State = {
  items: StateItem[];
};

export type Actions = {
  setLoadingIndicators: (operations: StateItem[]) => void;
};

export type LoadingIndicatorStore = State & Actions;

export const createLoadingIndicatorStore: ImmerStateCreator<
  LoadingIndicatorStore
> = set => ({
  items: [],
  setLoadingIndicators: (value: StateItem[]) => {
    setter<StateItem[]>("loadingIndicator", set, "items", [...value]);
  }
});
