export interface TenorImage {
  id: string;
  tenorUrl: string;
  shortTenorUrl: string;
  description: string;
  createdAt: Date;
  tags: string[];
  url: string;
  height: number;
  width: number;
  preview: TenorImagePreview;
}

export interface TenorImagePreview {
  url: string;
  height: number;
  width: number;
}

export interface TenorCategory {
  image: string;
  name: string;
}

export interface TenorResult {
  next: string;
  images: TenorImage[];
}

export enum ContentFilter {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
  OFF = "off"
}
export enum Theme {
  LIGHT = "light",
  DARK = "dark",
  AUTO = "auto"
}

const MEDIA_FILTER = "gif,tinygif";
const BASE_URL = "https://tenor.googleapis.com/v2/";

class Tenor {
  private api_key: string = "AIzaSyDuxpa1ygHeZnZVy3kgZ87PetgZnJdHNlU";
  private client_key: string = "leofinance";
  private country: string = "US";
  private locale: string = "en_US";
  private content_filter: ContentFilter = ContentFilter.HIGH;

  private async callApi(
    endpoint: string,
    params?: { [key: string]: any }
  ): Promise<Response> {
    const urlParams = new URLSearchParams({
      key: this.api_key,
      client_key: this.client_key,
      contentfilter: this.content_filter,
      media_filter: MEDIA_FILTER,
      locale: this.locale,
      country: this.country,
      ...params
    });

    const url = BASE_URL + endpoint + "?" + urlParams;
    return fetch(url)
      .then(res => {
        if (!res.ok) {
          console.error(res);
          console.error("[tenor-manager] Failed to fetch data from Tenor API");
        }
        return res;
      })
      .then(res => res.json())
      .catch(error => {
        console.error(error);
        console.error("[tenor-manager] Failed to fetch data from Tenor API");
      });
  }

  private praseResult(img: any): TenorImage {
    const preview = img["media_formats"]["tinygif"];
    const gif = img["media_formats"]["gif"];

    return {
      id: img.id,
      tenorUrl: img["itemurl"],
      shortTenorUrl: img.url,
      description: img["content_description"],
      createdAt: new Date(img.created * 1000),
      tags: img.tags,
      url: gif.url,
      width: gif.dims[0],
      height: gif.dims[1],
      preview: {
        url: preview.url,
        width: preview.dims[0],
        height: preview.dims[1]
      }
    };
  }

  public async search(query: string, limit: number = 50): Promise<TenorResult> {
    return this.callApi("search", {
      q: query,
      ar_range: "all",
      limit
    }).then((data: any) => {
      const results = data.results;
      const images = results.map(this.praseResult);
      return {
        next: data.next,
        images: images
      };
    });
  }

  public async categories(): Promise<TenorCategory[]> {
    return this.callApi("categories", {
      type: "featured"
    }).then((data: any) => {
      const tags = data.tags;
      return tags.map((tag: any) => ({
        name: tag["searchterm"],
        image: tag.image
      }));
    });
  }

  public async trending(limit: number = 50): Promise<TenorResult> {
    return this.callApi("featured", {
      ar_range: "all",
      limit
    }).then((data: any) => {
      const results = data.results;
      const images = results.map(this.praseResult);
      return {
        next: data.next,
        images: images
      };
    });
  }

  public async registerShare(
    image: TenorImage,
    searchTerm?: string
  ): Promise<void> {
    const params: any = { id: image.id };
    if (searchTerm) params["q"] = searchTerm;
    await this.callApi("registershare", params);
    return;
  }
}

export default Tenor;

