import { setter, type ImmerStateCreator } from "./";

type State = {
  search: boolean;
  reply: boolean;
};

type Actions = {
  setModal: (key: keyof State, value: boolean) => void;
};

export type ModalsStore = State & Actions;

export const createModalsStore: ImmerStateCreator<ModalsStore> = set => ({
  // state
  search: false,
  reply: false,

  // actions
  setModal: (key: keyof State, value: boolean) =>
    setter<boolean>("modals", set, key, value)
});

