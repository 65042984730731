import { produce } from "immer";

// types
import { ImmerStateCreator, setter } from "./";

type State = {
  draft: string;
  placeholderVideo: {};
  spk3Cookie: "";
  thumbnail: "";
  authoritySet: false | true;
};

type Actions = {
  setDraft: (draft: string) => void;
  set3SpeakCookie: (draft: string) => void;
  setPlaceHolderVideo: (link: string, preview: string) => void;
  setThumbnail: (thumbnail: string) => void;
  setAuthorityTrue: () => void;
};

export type MarkdownStore = State & Actions;

export const createMarkdownStore: ImmerStateCreator<MarkdownStore> = set => ({
  draft: "",
  placeholderVideo: {},
  spk3Cookie: "",
  thumbnail: "",
  authoritySet: false,
  setAuthorityTrue: () => setter("markdown", set, "authoritySet", true),
  setThumbnail: (thumbnail: string) =>
    setter("markdown", set, "thumbnail", thumbnail),
  setPlaceHolderVideo: (
    link: string,
    preview: string,
    permlink: string,
    id: string
  ) =>
    setter("markdown", set, "placeholderVideo", {
      link,
      preview,
      permlink,
      id
    }),
  set3SpeakCookie: (memo: string) =>
    setter("markdown", set, "spk3Cookie", memo),
  setDraft: (updated_draft: string) => {
    set(
      produce(state => {
        state.draft = updated_draft;
      })
    );
  }
});
