import { setter, type ImmerStateCreator } from ".";

interface PremiumRewards {
  total_hbd_payout: number;
  total_hive_payout: number;
  total_vesting_payout: number;
}

type State = {
  account: string | undefined;
  premium_rewards: PremiumRewards | null;
};

type Actions = {
  setAccount: (account: string | undefined) => void;
  setPremiumRewards: (rewards: PremiumRewards | null) => void;
};

export type DashboardStore = State & Actions;

export const createDashboardStore: ImmerStateCreator<DashboardStore> = set => ({
  // state
  account: undefined,
  premium_rewards: null,

  // actions
  setAccount: (account: string | undefined) =>
    setter<boolean>("dashboard", set, "account", account),
  setPremiumRewards: (rewards: PremiumRewards | null) =>
    setter<boolean>("dashboard", set, "premium_rewards", rewards)
});

