import { setter, type ImmerStateCreator } from "./";

// types
import type { ParsedAccount } from "~/utils/hive";
import type { Bookmark } from "~/utils/leocache";

export interface PremiumState {
  is_premium: boolean;
  timestamp: string;
  fetched: boolean;
}

export interface AccountKeys {
  signature: string | null;
  publicKey: string | null;
  proxy: "hivesigner" | "keychain" | null;
}

type State = {
  activeAccount: ParsedAccount | null;
  keys: AccountKeys;
  premium: PremiumState;
  bookmarks: Bookmark[] | null;
};

type Actions = {
  setActiveAccount: (account: ParsedAccount | null) => void;
  setKeys: (keys: AccountKeys) => void;
  setPremium: (premium: PremiumState) => void;
  setBookmarks: (bookmarks: Bookmark[]) => void;
};

export type AccountStore = State & Actions;

export const createAccountStore: ImmerStateCreator<AccountStore> = (
  set,
  get,
  store
) => ({
  // state
  activeAccount: null,
  keys: {
    signature: null,
    publicKey: null,
    proxy: null
  },
  premium: {
    is_premium: false,
    timestamp: "",
    fetched: false
  },
  bookmarks: null,

  // actions
  setActiveAccount: (account: ParsedAccount | null) => {
    const currentAccount = get().activeAccount;
    // Only update if incoming account data is different from current data
    if (JSON.stringify(currentAccount) !== JSON.stringify(account)) {
      setter<ParsedAccount | null>("account", set, "activeAccount", account);
    }
  },
  setKeys: (keys: AccountKeys) =>
    setter<AccountKeys>("account", set, "keys", keys),
  setPremium: (premium: PremiumState) => {
    const currentPremium = store.getState().premium;

    // Only update if incoming premium data is different from current data
    if (JSON.stringify(currentPremium) !== JSON.stringify(premium)) {
      setter<PremiumState>("account", set, "premium", premium);
    }
  },
  setBookmarks: (bookmarks: Bookmark[]) =>
    setter<Bookmark[]>("account", set, "bookmarks", bookmarks)
});
