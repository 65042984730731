import { create, type StateCreator } from "zustand";
import produce, { enableMapSet } from "immer";

// middlewares
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

// slices
import { createWalletStore, type WalletStore } from "./wallet";
import { createModalsStore, type ModalsStore } from "./modals";
import { createAccountStore, type AccountStore } from "./account";
import { createMarkdownStore, type MarkdownStore } from "./markdown";
import { createSettingsStore, type SettingsStore } from "./settings";
import { createUtilitiesStore, type UtilitiesStore } from "./utilities";
import { createDashboardStore, type DashboardStore } from "./dashboard";
import { createDiscussionStore, type DiscussionStore } from "./discussion";
import { createLeaderboardStore, type LeaderboardStore } from "./leaderboard";
import { createThreadsStore, type ThreadsStore } from "./threads";
import {
  createLoadingIndicatorStore,
  type LoadingIndicatorStore
} from "./loading-indicator";

enableMapSet();

export type ImmerStateCreator<T> = StateCreator<
  T,
  [["zustand/immer", never], never],
  [],
  T
>;

export type AppState = {
  wallet: WalletStore;
  modals: ModalsStore;
  account: AccountStore;
  settings: SettingsStore;
  markdown: MarkdownStore;
  utilities: UtilitiesStore;
  dashboard: DashboardStore;
  discussion: DiscussionStore;
  leaderboard: LeaderboardStore;
  loadingIndicator: LoadingIndicatorStore;
  threads: ThreadsStore;
};

export const useAppStore = create<AppState>()(
  devtools(
    persist(
      immer((...args) => {
        return {
          wallet: createWalletStore(...args),
          modals: createModalsStore(...args),
          account: createAccountStore(...args),
          markdown: createMarkdownStore(...args),
          settings: createSettingsStore(...args),
          utilities: createUtilitiesStore(...args),
          dashboard: createDashboardStore(...args),
          discussion: createDiscussionStore(...args),
          leaderboard: createLeaderboardStore(...args),
          loadingIndicator: createLoadingIndicatorStore(...args),
          threads: createThreadsStore(...args)
        };
      }),
      {
        name: "LeoStore",
        skipHydration: true,
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);

export const setter = <T>(
  base: keyof AppState,
  set: (state: any) => void,
  field: string,
  value: T
) => {
  set(
    produce(state => {
      if ((state as any)[base][field] === undefined) {
        console.warn("[store-setter] base or field not found.");
        return;
      }
      (state as any)[base][field] = value;
    })
  );
};
