import { setter, type ImmerStateCreator } from "./";
import { type Thread } from "~/utils/leocache";
import { type PostPreviewData } from "~/components/posts/PostPreviewData";
import { type StateSnapshot } from "react-virtuoso";
import produce from "immer";

interface CachedSession {
  threads: Thread[];
  nextPage?: string;
  session?: string;
}

export type State = {
  activePost: PostPreviewData | null;
  cachedSession: Map<string, CachedSession>;
  measurementsCache: Map<string, StateSnapshot>;
  pushStartArray: (thread: Thread) => void;
  setActivePost: (post: PostPreviewData) => void;
};

type Actions = {
  setActivePost: (post: PostPreviewData) => void;
  setCachedSession: (key: string, session: CachedSession) => void;
  setMeasurementsCache: (key: string, snapshot: StateSnapshot) => void;
  setPushStartArray: (pushStartArray: any) => void;
  clearCache: () => void;
};

export type ThreadsStore = State & Actions;

export const createThreadsStore: ImmerStateCreator<ThreadsStore> = (
  set,
  get,
  store
) => ({
  // state
  activePost: null,
  cachedSession: new Map(),
  measurementsCache: new Map(),
  pushStartArray: () => null,

  // actions
  setActivePost: (post: PostPreviewData) =>
    setter<PostPreviewData>("threads", set, "activePost", post),
  setActiveThread: (thread: Thread | null) =>
    setter<Thread | null>("threads", set, "activeThread", thread),
  setCachedSession: (key: string, session: CachedSession) => {
    set(
      produce(state => {
        state["threads"]["cachedSession"].set(key, session);
      })
    );
  },
  setMeasurementsCache: (key: string, snapshot: StateSnapshot) => {
    set(
      produce(state => {
        state["threads"]["measurementsCache"].set(key, snapshot);
      })
    );
  },
  setPushStartArray: (post: PostPreviewData) =>
    setter<PostPreviewData>("threads", set, "pushStartArray", post),
  clearCache: () => {
    setter("threads", set, "cachedSession", new Map());
    setter("threads", set, "measurementsCache", new Map());
  }
});
