import { setter, type ImmerStateCreator } from "./";

// types
import Tenor from "~/utils/tenor";
import type { PollOptions } from "~/components/format/Polls";

export interface PollContext {
  pollOptions: PollOptions;
  pollCreated: boolean;
  insertedToEditor: boolean;
  editPoll: () => void;
  removePoll: () => void;
}

export interface ListsHandler {
  modal: boolean;
  author: string | undefined;
}

type State = {
  tenor: Tenor;
  poll: PollContext | null;
  lists: ListsHandler;
};

type Actions = {
  setTenor: (tenor: Tenor) => void;
  setPoll: (poll: PollContext) => void;
  setLists: (handler: ListsHandler) => void;
};

export type UtilitiesStore = State & Actions;

export const createUtilitiesStore: ImmerStateCreator<UtilitiesStore> = set => ({
  // state
  tenor: new Tenor(),
  poll: null,
  lists: {
    modal: false,
    author: undefined
  },

  // actions
  setTenor: (tenor: Tenor) => setter<Tenor>("utilities", set, "tenor", tenor),
  setPoll: (poll: PollContext) =>
    setter<PollContext>("utilities", set, "poll", poll),
  setLists: (handler: ListsHandler) =>
    setter<ListsHandler>("utilities", set, "lists", handler)
});

